import { useCallback } from "react"

import {useTranslation} from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useHorizontalSignUpdate } from "../../hooks/mutations/useHorizontalSignUpdate"
import { useHorizontalSign } from "../../hooks/queries/horizontalSigning/useHorizontalSigns"

import { HorizontalSignDetailsForm } from "./HorizontalSignDetailsForm"

export const HorizontalSignDetailsFormPost = (props) => {

    const {
        close,
        signId,
        ...otherProps
    } = props

    const {t} = useTranslation(I18N_NAMESPACE)

    const { data: horizontalSign } = useHorizontalSign(signId)
    const mutation = useHorizontalSignUpdate(signId, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.snackbar.success"),
    })

    const onSubmit = useCallback((values) => {
        mutation.mutate({ tags: values.tags })
        close()
    }, [close, mutation])

    return <HorizontalSignDetailsForm
        {...otherProps}
        signTags={horizontalSign?.tags || []}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

HorizontalSignDetailsFormPost.propTypes = {
    close: PropTypes.func.isRequired,
    signId: PropTypes.string.isRequired,
}