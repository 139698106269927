import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { KpiCard } from "@l2r-front/l2r-ui"

import { appRoad } from "../../../../common/constants/appRoad"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { EPDTag } from "../../components/EPDTag/EPDTag"
import { notationLegend } from "../../constants/getNotationConfig"
import { EPD_TAG_HEIGHT, EPD_TAG_START_WIDTH } from "../../constants/layout"
import { GLOBAL_GRADE_TYPE } from "../../constants/roadInfoConfig"
import { useRelevantNetworkGradings } from "../../hooks/queries/networkGradings/useRelevantNetworkGradings"
import { getPreviousNetworkGrading } from "../../utils/networkGradingsUtils"

import * as Styled from "./NotationEPDKpi.styled"

export const NotationEPDKpi = (props) => {

    const { t } = useTranslation([I18N_NAMESPACE])
    const { selectedNetwork } = useNetworksStateContext()
    const { isError, isLoading, sortedRelevantNetworkGradings } = useRelevantNetworkGradings(selectedNetwork?.slug)

    const networkGrading = useMemo(() => {
        if (sortedRelevantNetworkGradings) {
            return getPreviousNetworkGrading(sortedRelevantNetworkGradings, new Date().getFullYear(), true)
        }

        return null
    }, [sortedRelevantNetworkGradings])

    const notationIndex = useMemo(() => {
        if (!networkGrading || !networkGrading.gradesWeightedAverage) {
            return -1
        }

        const globalGrade = networkGrading.gradesWeightedAverage[GLOBAL_GRADE_TYPE]
        return notationLegend.findIndex((nl, index) => {
            return ((index === notationLegend.length - 1) && (nl.minValue <= globalGrade) && (nl.maxValue >= globalGrade))
                || ((nl.minValue <= globalGrade) && (nl.maxValue > globalGrade))
        })
    }, [networkGrading])

    if (isError) {
        return <Styled.KpiError
            message={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            path={`${appRoad.path}/${appRoad.modules.roadway.path}`}
            title={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            id="notation-edp-kpi-error"
            {...props}
        />
    }

    if (isLoading) {
        return <Styled.KpiSkeleton {...props} />
    }

    return (
        <KpiCard
            id="notation-edp-kpi"
            path={`${appRoad.path}/${appRoad.modules.roadway.path}`}
            {...props}
        >
            <Styled.Title variant="H2">{t(I18N_NAMESPACE, "containers.notationKpi.title")}</Styled.Title>
            <Styled.Container>
                <Styled.EPDContainer>
                    {
                        [...notationLegend].reverse().map((legendItem, index) => {
                            return <EPDTag key={`epd-${legendItem.shortLabel}`} direction="right" color={legendItem.color} width={EPD_TAG_START_WIDTH + EPD_TAG_HEIGHT / 2 * index} label={legendItem.shortLabel} />
                        })
                    }
                </Styled.EPDContainer>
                {notationIndex >= 0 && <Styled.NotationContainer>
                    <Styled.Marker id="edp-marker" index={notationLegend.length - 1 - notationIndex}>
                        <EPDTag direction="left" color={notationLegend[notationIndex].color} width={EPD_TAG_START_WIDTH} label={notationLegend[notationIndex].shortLabel} />
                        <Styled.AuditLabel variant="Regular">{`${t(I18N_NAMESPACE, "containers.roadStateSelector.survey")} ${networkGrading.year}`}</Styled.AuditLabel>
                    </Styled.Marker>
                </Styled.NotationContainer>
                }
            </Styled.Container>
        </KpiCard>
    )
}

NotationEPDKpi.propTypes = KpiCard.propTypes