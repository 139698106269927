import { useMemo } from "react"

import { Formik } from "formik"
import { isEqual } from "lodash"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { FormikMapPointEditor, MapStyles } from "@l2r-front/l2r-map"
import { BaseNetworkReferentialLayer } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, useIsMobileDevice, useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./VerticalSignPoleEditForm.styled"

export const VerticalSignPoleEditForm = (props) => {

    const {
        className,
        referential,
        startingPosition,
        startingRoad,
        onCancel,
        onSubmit,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()
    const theme = useTheme()

    const initialValues = useMemo(() => {
        return ({
            position: startingPosition,
            road: {
                typeid: 1,
                road: startingRoad,
                start: 0,
                end: 1,
            },
        })

    }, [startingPosition, startingRoad])

    return <Formik
        className={className}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {({ values, errors }) => {
            const areInitialValues = isEqual(initialValues, values)
            const hasErrors = Object.keys(errors).length > 0
            return <Styled.Form>
                <Styled.MapContainer isMobile={isMobile}>
                    <FormikMapPointEditor
                        positionFieldName="position"
                        roadFieldName="road"
                        referential={referential}
                    >
                        <BaseNetworkReferentialLayer
                            mapStyle={MapStyles.satellite}
                            selectedRoad={values.road.road}
                            selectedRoadColor={theme.palette["border/border-primary"].main}
                            interactive={false}
                        />
                    </FormikMapPointEditor>
                </Styled.MapContainer>
                <Styled.ButtonsWrapper>
                    <CTAButton
                        id="cancel-button"
                        onClick={() => {
                            onCancel()
                        }}
                        variant="outlined"
                    >
                        {t(I18N_NAMESPACE, "containers.verticalSignPoleLocationEditForm.cancel")}
                    </CTAButton>
                    <Styled.SubmitButton
                        id="submit-button"
                        color="cta-bg/cta-bg-primary"
                        type="submit"
                        variant="contained"
                        disabled={areInitialValues || hasErrors}
                    >
                        {t(I18N_NAMESPACE, "containers.verticalSignPoleLocationEditForm.submit")}
                    </Styled.SubmitButton>
                </Styled.ButtonsWrapper>
            </Styled.Form>
        }}
    </Formik>
}

VerticalSignPoleEditForm.propTypes = {
    referential: PropTypes.object,
    startingPosition: PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
    }).isRequired,
    startingRoad: PropTypes.string,
    className: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}