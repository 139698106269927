import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"
import { getContrastTextColor } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { impacts } from "../../constants/impacts"

import * as Styled from "./TaskImpactBadge.styled"

export function TaskImpactBadge(props) {

    const {
        className,
        id,
        impact,
        variant,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const theme = useTheme()

    const mainColor = impacts?.[impact]?.backgroundColor || theme.palette["texts/text-placeholder"].main

    const badgeConfig = useMemo(() => {
        const constrastMainColor = getContrastTextColor(mainColor)

        return ({
            ...otherProps,
            backgroundColor: variant === "filled" ? mainColor : theme.palette.common.white,
            text: t(I18N_NAMESPACE, impacts?.[impact]?.acronyms || "components.taskImpactBadge.noImpact"),
            textColor: variant === "filled" ? constrastMainColor : mainColor,
        })
    }, [impact, mainColor, otherProps, t, theme, variant])

    return <Styled.ColoredBadge
        {...badgeConfig}
        borderColor={mainColor}
        className={className}
        id={id}
        textVariant="H3"
    />
}

TaskImpactBadge.defaultProps = {
    variant: "filled",
}

TaskImpactBadge.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    impact: PropTypes.number,
    variant: PropTypes.oneOf(["filled", "outlined"]),
}