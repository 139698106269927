import { CloseIcon as Close } from "@l2r-front/l2r-icons"
import {
    Box as UiBox,
    IconButton as UiIconButton,
    Typography,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

const POPUP_WIDTH = 514

export const Box = styledForMobile(UiBox)(({ theme, isMobile }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    bottom: isMobile ? "5%" : 0,
    top: isMobile ? "5%" : 0,
    left: isMobile ? "5%" : 0,
    right: isMobile ? "5%" : 0,
    margin: "auto",
    height: isMobile ? "90" : "fit-content",
    width: isMobile ? "90%" : POPUP_WIDTH,
    padding: isMobile ? theme.spacing(8, 4) : theme.spacing(8, 8, 8, 8),
    position: "absolute",
}))

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(8),
}))

export const Desc = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    padding: 0,
    position: "absolute",
    height: 24,
    right: theme.spacing(3),
    top: theme.spacing(3),
    width: 24,
}))

export const CloseIcon = styled(Close)({
    height: 16,
    width: 16,
})