import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../common/constants/i18n"

import { getRoadworkLegendConfig } from "../constants/getRoadworkConfig"

export function useRoadworksLegend() {

    const { t } = useTranslation(I18N_NAMESPACE)

    return [
        {
            layerName: "roadworks",
            legendData: getRoadworkLegendConfig(t),
            title: t(I18N_NAMESPACE, "containers.networkRoadwork.legend.title"),
        },
    ]
}