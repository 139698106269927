import { useMemo } from "react"

import { Form, Formik } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { SearchIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useMediaQuery, useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useHorizontalSigningProject } from "../../hooks/queries/horizontalSigning/useHorizontalSigningProject"

import * as Styled from "./HorizontalSignFiltersForm.styled"

const defaultFormValues = {
    tags: [],
}

export function HorizontalSignFiltersForm(props) {

    const {
        activeFilters,
        className,
        close,
        onSubmit,
        resetFilters,
    } = props

    const { data: project } = useHorizontalSigningProject()
    const { t } = useTranslation([I18N_NAMESPACE])
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const initialFilters = defaultFormValues

    const displayedTags = useMemo(() => {
        if (project?.tags) {
            return project?.tags.sort()
        }
    }, [project])

    const initialValues = useMemo(() => {
        return ({
            tags: activeFilters.tags || [],
        })

    }, [activeFilters])

    return <Formik
        className={className}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {({ resetForm, setFieldValue }) => <Form>
            <Styled.FormWrapper>
                <Styled.InputsWrapper>
                    {displayedTags && <Styled.TagFilter
                        id="tags-filters-select"
                        disableClearable
                        label={t(I18N_NAMESPACE, "forms.horizontalSignFiltersForm.inputs.tags.label")}
                        name="tags"
                        limitTags={2}
                        options={displayedTags}
                        setFieldValue={setFieldValue}
                        defaultValue={initialValues?.tags}
                        popupIcon={<SearchIcon />}
                        noOptionsText={t(I18N_NAMESPACE, "forms.horizontalSignFiltersForm.inputs.tags.noOptions")}
                    />}
                </Styled.InputsWrapper>
                <Styled.ButtonsWrapper>
                    <Styled.Button
                        id="reset-filters-button"
                        color="error"
                        onClick={() => {
                            resetForm({ values: { ...defaultFormValues, ...initialFilters, tags: [] } })
                            const urlParams = resetFilters()
                            close(urlParams)
                        }}
                        variant="text"
                    >
                        {isSmallScreen ? t(I18N_NAMESPACE, "forms.horizontalSignFiltersForm.buttons.delete%small")
                            : t(I18N_NAMESPACE, "forms.horizontalSignFiltersForm.buttons.delete")}
                    </Styled.Button>
                    <Styled.Wrapper>
                        <Styled.Button
                            id="cancel-filters-button"
                            color="cta-bg/cta-bg-primary"
                            onClick={() => {
                                resetForm({ values: initialValues, ...initialFilters, tags: [] })
                                close()
                            }}
                            variant="text"
                        >
                            {t(I18N_NAMESPACE, "forms.horizontalSignFiltersForm.buttons.cancel")}
                        </Styled.Button>
                        <Styled.SubmitButton
                            id="submit-filters-button"
                            color="cta-bg/cta-bg-primary"
                            type="submit"
                            variant="contained"
                        >
                            {t(I18N_NAMESPACE, "forms.horizontalSignFiltersForm.buttons.confirm")}
                        </Styled.SubmitButton>
                    </Styled.Wrapper>
                </Styled.ButtonsWrapper>
            </Styled.FormWrapper>
        </Form>}
    </Formik>
}

HorizontalSignFiltersForm.propTypes = {
    filters: PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
    className: PropTypes.string,
    formData: PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
    onSubmit: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
}