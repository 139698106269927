import { useMemo } from "react"

import lodash from "lodash"

import {
    Layer,
    DAMAGES_SOURCE,
    DAMAGES_LAYER_POINT,
    DAMAGES_LAYER_LINE,
    useOrderingLayers,
    getInterpolatedSelectableLineLayerWidth,
    getInterpolatedSelectableCircleLayerRadius,
    getSelectableLineSortKey,
} from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { DAMAGES_URL_PREFIX_PARAM } from "../../../../common/constants/urlParams"
import { getDamagesLineLayerStyle, getDamagesPointLayerStyle } from "../../constants/getDamagesConfig"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"

import { DamagesSource } from "../DamagesSource"

export function DamagesLayer() {

    const { selectedLinearLocations } = useNetworksStateContext()
    const { selectedNetworkAssesmentType } = useRoadwayStateContext()

    const damageType = selectedNetworkAssesmentType?.replace(DAMAGES_URL_PREFIX_PARAM, "")

    const damagesLineLayerConfig = useMemo(() => {
        const selectedRoad = selectedLinearLocations?.[0].road
        return lodash.merge(
            getDamagesLineLayerStyle(damageType, selectedRoad),
            {
                "paint": {
                    "line-width": getInterpolatedSelectableLineLayerWidth(selectedRoad),
                },
                "layout": {
                    "line-sort-key": getSelectableLineSortKey(selectedRoad),
                },
            },
        )
    }, [selectedLinearLocations, damageType])

    const damagesPointLayerConfig = useMemo(() => {
        const selectedRoad = selectedLinearLocations?.[0].road
        return lodash.merge(
            getDamagesPointLayerStyle(damageType, selectedRoad),
            {
                "paint": {
                    "circle-radius": getInterpolatedSelectableCircleLayerRadius(selectedRoad),
                },
                "layout": {
                    "circle-sort-key": getSelectableLineSortKey(selectedRoad),
                },
            },
        )
    }, [selectedLinearLocations, damageType])

    useOrderingLayers([DAMAGES_LAYER_POINT, DAMAGES_LAYER_LINE])

    return (
        <DamagesSource
            id={DAMAGES_SOURCE}
            tolerance={.001}
            damageType={damageType}
        >
            <Layer
                id={DAMAGES_LAYER_POINT}
                {...damagesPointLayerConfig}
            />

            <Layer
                id={DAMAGES_LAYER_LINE}
                {...damagesLineLayerConfig}
            />
        </DamagesSource>
    )
}
